<template>
  <div>
    <div v-if="!reading">
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <div>
            <h4 class="text-capitalize text-center">
              Video tutorials to get you started
            </h4>
            <div style="width: 100%; height: 270px">
              <iframe
                width="100%"
                height="270"
                src="https://www.youtube.com/embed/bqBd5_7UXBQ"
                title="Konvey Walkthrough Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="peojectEnv !== 'production'">
        <v-col
          v-for="(training, i) in trainingTexts"
          :key="i"
          cols="12"
          md="2"
          :offset-md="i === 0 || i === 5 ? '1' : 0"
          class="pointer"
          @click="read(training)"
        >
          <div class="text-left mb-10" style="height: 70px">
            <v-img height="100%" width="100%" :src="training.img_url"></v-img>
            <div class="text-capitalize" style="line-height: 13px">
              <small>{{ training.description }}</small>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card flat class="pa-4">
            <div>
              <v-toolbar flat class="d-flex justify-center">
                <v-card-title class="text-break">
                  {{ article.title }}
                </v-card-title>
              </v-toolbar>
              <div class="text-left">
                <p>{{ article.content }}</p>
              </div>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="primary depressed rounded-xl text-capitalize"
                @click="reading = false"
              >
                Back to trainings
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Training",
  data() {
    return {
      trainingTexts: [
        {
          img_url: require("@/assets/trainings/1.png"),
          description: "What is video messaging",
        },
        {
          img_url: require("@/assets/trainings/2.png"),
          description: "How to send video request",
        },
        {
          img_url: require("@/assets/trainings/3.png"),
          description: "how to find your recorded videos",
        },
        {
          img_url: require("@/assets/trainings/4.png"),
          description:
            "how to find videos you requested from clients, customers or team members",
        },
        {
          img_url: require("@/assets/icons/whatsapp.png"),
          description: "how to change your Konvey account password",
        },
        {
          img_url: require("@/assets/icons/whatsapp.png"),
          description: "how to add team members",
        },
        {
          img_url: require("@/assets/trainings/7.png"),
          description: "how to setup custom domain and CNAME",
        },
        {
          img_url: require("@/assets/icons/whatsapp.png"),
          description: "how to add email capture or CTA to your recorded video",
        },
        {
          img_url: require("@/assets/icons/whatsapp.png"),
          description: "how to integrate third-party apps to konvey account",
        },
        {
          img_url: require("@/assets/icons/whatsapp.png"),
          description: "how to add subtitles to video",
        },
      ],
      reading: false,
      article: {
        title: "",
        content: "",
        img_url: "",
      },
      peojectEnv: process.env.NODE_ENV,
    };
  },
  methods: {
    read(row) {
      this.reading = true;
      const article = this.article;
      article.title = row.description;
      article.img_url = row.img_url;
    },
  },
};
</script>

<style scoped></style>
